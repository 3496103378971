@font-face
  font-family: Evolventa
  src: url("./Evolventa-Regular.ttf")
  font-weight: 400
  font-style: normal

@font-face
  font-family: Evolventa
  src: url("./Evolventa-Bold.ttf")
  font-weight: 700
  font-style: normal