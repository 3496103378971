.tabBar
  min-height: 300px
  width: 100%
  max-width: 379px
  color: white
  position: relative
  display: flex
  flex-direction: column
  background-color: #46A31F
  border-radius: 12px
  margin-top: 80px

.tabs
  position: absolute
  bottom: calc(100% - 10px)
  display: flex
  left: 0
  right: 0
  align-items: flex-end
  
  &>button
    background-color: #03602B
    border: none
    height: 80px
    border-radius: 20px 20px 0 0 
    padding-top: 8px
    flex: 1 1 50px
    transform: scale(0.9) translate(0, 10px)
    transition: transform 0.18s, background-color 0.17s
    padding-bottom: 10px

    &:last-child
      margin-left: auto


    img
      width: 36px
      height: 36px


    &.active
      background-color: #46A31F
      transform: scale(1)

      img
        width: 48px
        height: 48px
 

.content
  background-color: #46A31F
  z-index: 2
  position: relative
  flex: 1 1 auto
  text-align: left
  padding: 16px 20px
  font-weight: 400
  font-size: 16px
  line-height: 1.2
  border-radius: 12px

  span
    color: #E5FFE1
    padding-top: 10px
    white-space: pre-wrap

.title
  font-weight: 700
  font-size: 24px
  line-height: 29px
  color: #F5F5F5
  margin-top: 10px
  max-width: 300px
  margin-bottom: 8px

.markers
  align-self: center
  display: flex
  justify-content: center
  margin-bottom: 12px

.marker
  width: 10px
  height: 10px
  background-color: white
  border-radius: 9999px
  transform: scale(0.7)
  opacity: 0.5
  transition: opacity 0.4s, transform 0.4s
  
  &:not(:first-child)
    margin-left: 4px

  &.active
    opacity: 1
    transform: scale(1)