.btn
  position: fixed
  bottom: 16px
  border-radius: 8px
  width: 100%
  max-width: 180px
  display: flex
  align-items: center
  color: #fff
  text-align: center
  justify-content: center
  font-weight: 700
  right: calc(50% - 90px)
  background: #006128
  height: 44px
  border: none
  background-size: cover
  background-position: center
  z-index: 10

  svg
    margin-right: 10px