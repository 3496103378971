.h
  min-height: 100vh
  min-height: calc(var(--vh, 1vh) * 100)
  // overflow: hidden

.flex
  display: flex
  align-items: center

h2
  display: block
  font-size: 1.5em
  margin-block-start: 0.83em
  margin-block-end: 0.83em
  margin-inline-start: 0px
  margin-inline-end: 0px
  font-weight: bold
  line-height: 1.2

.stack
  display: flex
  flex-direction: column
  align-items: center
  background: #46A320
  border-radius: 12px
  max-width: 380px
  width: 100%
  margin-bottom: 1vh
  margin-top: auto
  padding: 50px
  color: #fff
  font-weight: 400
  font-size: 18px
  line-height: 22px

.anim-wrapper
  align-self: stretch
  display: flex
  flex-direction: column
  align-items: center
  &>*
    position: absolute