.containerdesk
  background-image: url(./bg.jpg)
  background-size: cover
  background-position: center
  padding: 2vh 8vw

  .block
    max-width: 490px
    width: 100%
    padding: 40px
    margin-top: 4vh
    background: #46A320
    box-shadow: 0px 0px 16px rgb(17 43 20 / 15%)
    border-radius: 16px

  h1
    font-weight: 700
    font-size: 38px
    color: #fff
    margin: 0
    line-height: 1.2

  span
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #F1F1F1
    display: block
    max-width: 370px
    margin-top: 2vh

  .imgqr
    display: block
    margin: 4vh auto

  .btn
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    padding: 16px 48px
    background: rgba(250, 250, 250, 0.1)
    border: 1px solid #FFFFFF
    border-radius: 12px
    font-weight: 700
    font-size: 16px
    line-height: 19px
    color: #fff
    text-decoration: none
    position: relative
    &:before
      content: ""
      background-image: url(./tg.svg)
      position: absolute
      width: 45px
      height: 24px
      left: 15px
      background-repeat: no-repeat
      border-right: 1px solid #ffffff94
    &:hover
      border-color: rgba(250, 250, 250, 0.7)
      background: rgba(250, 250, 250, 0.3)
  
.container
  background: #F8FFF8
  display: flex
  flex-direction: column
  align-items: center
  padding: 0 10px
  justify-content: space-between
  text-align: center
  background-size: cover
  background-position: center
  color: #333

  h1
    max-width: 380px
    font-weight: 700
    font-size: 30px
    line-height: 32px
    color: #006128
    display: block
    text-align: left
    margin: auto 0 10px  

  .imgLogo
    display: block
    background: #fff
    padding: 10vh 5vw 5vh
    width: 100%
    opacity: 0.8
    border: 1px solid #EEEEEE
    border-radius: 24px
    margin-top: -5vh
    max-width: 380px
    margin-bottom: 20px

  .tg
    display: flex
    width: 80px
    height: 70px
    background: rgba(70, 163, 32, 0.1)
    border: 2px solid #006128
    border-radius: 12px
    padding: 20px
    position: relative
    align-items: center
    justify-content: center
    margin-right: auto

  .btn
    background: #006128
    border-radius: 12px
    font-weight: 700
    font-size: 16px
    line-height: 25px
    color: #FAFAFA
    padding: 0 30px
    height: 70px
    width: 100%
    margin-left: 10px
    position: relative
    display: flex
    align-items: center
    justify-content: center

    &.active
      &:before
        content: ""
        background-image: url(./icons/icon-camera-mini.svg)
        width: 45px
        height: 30px
        background-repeat: no-repeat


  



.listItem
  display: flex
  align-items: center
  text-align: left
  padding-left: 8px
  padding-right: 8px

  &:not(:first-child)
    margin-top: 16px
  
  svg
    flex-shrink: 0
    margin-right: 12px