.container
  width: 100vw
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  position: relative

  video
    position: absolute
    left: 0
    top: 0
    width: 100vw
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    object-fit: cover

  canvas
    position: absolute
    left: 0
    top: 0
    object-fit: cover


.ui
  &>*
    position: absolute