.btn
  background: #006128
  border-radius: 12px
  margin-bottom: 10%
  max-width: 380px
  width: 100%
  font-weight: 700
  font-size: 20px
  line-height: 24px
  color: #FAFAFA
  padding: 20px 0

.BigIcon
  width: 128px
  height: 128px
  background: #0061285c
  padding: 30px
  border-radius: 64px
  display: flex
  justify-content: center